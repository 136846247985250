// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file trainers/models/horse.proto (package connectrpc.management.trainers.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file trainers/models/horse.proto.
 */
export const file_trainers_models_horse: GenFile = /*@__PURE__*/
  fileDesc("Cht0cmFpbmVycy9tb2RlbHMvaG9yc2UucHJvdG8SIWNvbm5lY3RycGMubWFuYWdlbWVudC50cmFpbmVycy52MSLqAgoFSG9yc2USEAoIaG9yc2VfaWQYASABKAMSFwoPbWFzdGVyX2hvcnNlX2lkGAIgASgJEhkKEW9yZ2FuaXphdGlvbl91dWlkGAMgASgJEhMKC3N0YWJsZV91dWlkGAQgASgJEgwKBG5hbWUYBSABKAkSDgoGZ2VuZGVyGAYgASgJEgsKA2FnZRgHIAEoBRISCgpiaXJ0aF95ZWFyGAggASgFEhEKCWluX3N0YWJsZRgJIAEoCBIdChBwcm9maWxlX3BpY19wYXRoGAogASgJSACIAQESIAoTbGFzdF9yZXBvcnRfc2VudF9hdBgLIAEoCUgBiAEBEkYKDW1hbmFnZV9zdGF0dXMYDCABKA4yLy5jb25uZWN0cnBjLm1hbmFnZW1lbnQudHJhaW5lcnMudjEuTWFuYWdlU3RhdHVzQhMKEV9wcm9maWxlX3BpY19wYXRoQhYKFF9sYXN0X3JlcG9ydF9zZW50X2F0KmUKDE1hbmFnZVN0YXR1cxIdChlNQU5BR0VfU1RBVFVTX1VOU1BFQ0lGSUVEEAASGQoVTUFOQUdFX1NUQVRVU19NQU5BR0VEEAESGwoXTUFOQUdFX1NUQVRVU19VTk1BTkFHRUQQAmIGcHJvdG8z");

/**
 * @generated from message connectrpc.management.trainers.v1.Horse
 */
export type Horse = Message<"connectrpc.management.trainers.v1.Horse"> & {
  /**
   * @generated from field: int64 horse_id = 1;
   */
  horseId: bigint;

  /**
   * @generated from field: string master_horse_id = 2;
   */
  masterHorseId: string;

  /**
   * @generated from field: string organization_uuid = 3;
   */
  organizationUuid: string;

  /**
   * @generated from field: string stable_uuid = 4;
   */
  stableUuid: string;

  /**
   * @generated from field: string name = 5;
   */
  name: string;

  /**
   * @generated from field: string gender = 6;
   */
  gender: string;

  /**
   * @generated from field: int32 age = 7;
   */
  age: number;

  /**
   * @generated from field: int32 birth_year = 8;
   */
  birthYear: number;

  /**
   * @generated from field: bool in_stable = 9;
   */
  inStable: boolean;

  /**
   * @generated from field: optional string profile_pic_path = 10;
   */
  profilePicPath?: string;

  /**
   * @generated from field: optional string last_report_sent_at = 11;
   */
  lastReportSentAt?: string;

  /**
   * @generated from field: connectrpc.management.trainers.v1.ManageStatus manage_status = 12;
   */
  manageStatus: ManageStatus;
};

/**
 * Describes the message connectrpc.management.trainers.v1.Horse.
 * Use `create(HorseSchema)` to create a new message.
 */
export const HorseSchema: GenMessage<Horse> = /*@__PURE__*/
  messageDesc(file_trainers_models_horse, 0);

/**
 * @generated from enum connectrpc.management.trainers.v1.ManageStatus
 */
export enum ManageStatus {
  /**
   * @generated from enum value: MANAGE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MANAGE_STATUS_MANAGED = 1;
   */
  MANAGED = 1,

  /**
   * @generated from enum value: MANAGE_STATUS_UNMANAGED = 2;
   */
  UNMANAGED = 2,
}

/**
 * Describes the enum connectrpc.management.trainers.v1.ManageStatus.
 */
export const ManageStatusSchema: GenEnum<ManageStatus> = /*@__PURE__*/
  enumDesc(file_trainers_models_horse, 0);

